(function () {
    'use strict';

    angular.module('informaApp')
        .component('trendsChartTable', {
            templateUrl: 'components/trends-chart-table/template.html',
            controller: TrendsChartTableController,
            bindings: {
                source: '<'
            }
        });

    function TrendsChartTableController($element) {
        const vm = this;

        this.$onInit = function () {
            vm.titleColumnData = {
                phase1: 'I',
                phase2: 'II',
                phase3: 'III',
                ndabla: 'NDABLA'
            };

            vm.options = [
                {name: 'LoA', value: 'loa'},
                {name: 'PoS', value: 'pos'},
                {name: 'Duration', value: 'duration'},
                {name: 'Number of transitions', value: 'numberOfTransitions'},
            ];
            vm.columns = vm.source.map(x => x.name);
            vm.tableData = getTableData(vm.source, vm.options);
        };

        this.activeCell = null;
        this.onMouseOver = onMouseOver;
        this.onMouseOut = onMouseOut;

        function onMouseOver(event) {
            if (vm.activeCell === event.currentTarget) {
                return;
            }

            vm.activeCell = event.currentTarget;

            const targetCell = $(event.currentTarget);
            const targetRow = targetCell.parent();
            const targetRowIndex = targetRow.index();
            const targetColumnIndex = targetCell.index();
            
            targetRow
                .parent()
                .find('> tr > td')
                .each(function() {
                    const cell = $(this);
                    const columnIndex = cell.index();
                    const rowIndex = cell.parent().index();

                    if (columnIndex !== 0 && (columnIndex !== targetColumnIndex || rowIndex !== targetRowIndex)) {
                        cell.addClass('secondary');
                    }

                    if (columnIndex === targetColumnIndex && rowIndex <= targetRowIndex || columnIndex <= targetColumnIndex && rowIndex === targetRowIndex) {
                        cell.addClass('active');
                    }
                });

            targetRow.closest('table').find('> thead > tr > th').filter(index => index === targetColumnIndex).addClass('active')
        }

        function onMouseOut(event) {
            const relatedTarget = $(event.relatedTarget).is('.phases-cell-container')
                ? event.relatedTarget
                : $(event.relatedTarget).closest('.phases-cell-container')[0];

            if (relatedTarget === vm.activeCell) {
                return;
            }

            vm.activeCell = null;
            const targetCell = $(event.currentTarget);

            targetCell.closest('table')
                .children()
                .find('> tr > td, > tr > th')
                .removeClass('active')
                .removeClass('secondary')
        }

        function getTableData(source, options) {
            return options.map(rowOptions => mapRow(source, rowOptions));
        }

        function mapRow(source, rowOptions) {
            return {
                name: rowOptions.name,
                columns: source.map(range => mapRange(range.data, rowOptions.value))
            };
        }

        function mapRange(range, key) {
            const [
                phase1,
                phase2,
                phase3,
                ndabla
            ] = range;

            return {
                phase1: phase1[key],
                phase2: phase2[key],
                phase3: phase3[key],
                ndabla: ndabla[key]
            };
        }
    }
})();
